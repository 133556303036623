<template>
  <div class="container">
    <a href="https://jiin.love/">
      <img class="logo" src="/icon/logo-sm.png" alt="모두의지인" />
    </a>
    <h2>나의 결혼 유형은</h2>
    <h1 class="result-title">{{myMbti.title}}</h1>
    <h1 class="result-type">{{mbti}}</h1>
    <img class="result-img" :src="`/result/${mbti}.png`" alt="캐릭터 이미지" />
    <div class="status-list">
      <div class="row">
        <div class="item">매력 💕</div>
        <div :class="['status']">
          <div :class="['charm','bar',`length-${myMbti.status.charm}`]"></div>
        </div>
      </div>
      <div class="row">
        <div class="item">로맨스 🌹</div>
        <div :class="['status']">
          <div :class="['romance','bar', `length-${myMbti.status.romance}`]"></div>
        </div>
      </div>
      <div class="row">
        <div class="item">섹슈얼 ❤️‍🔥</div>
        <div :class="['status']">
          <div :class="['sexual','bar', `length-${myMbti.status.sexual}`]"></div>
        </div>
      </div>
      <div class="row">
        <div class="item">추진력 💪🏻</div>
        <div :class="['status']">
          <div :class="['drive','bar', `length-${myMbti.status.drive}`]"></div>
        </div>
      </div>
      <div class="row">
        <div class="item">에너지 🔋</div>
        <div :class="['status']">
          <div :class="['energy','bar', `length-${myMbti.status.energy}`]"></div>
        </div>
      </div>
    </div>
    <h5 class="feature-head">{{myMbti.tag}}</h5>
    <p class="feature">{{myMbti.text}}</p>
    <div class="partner">
      <div class="item">
        <h3>최고의 파트너</h3>
        <div class="p-card">
          <img class="parter-img"  :src="`/result/${myMbti.good}.png`" alt="캐릭터 이미지" />
          <div class="title">{{good}}</div>
          <div class="type">{{myMbti.good}}</div>
        </div>
      </div>
      <div class="item">
        <h3>최악의 파트너</h3>
        <div class="p-card">
          <img class="parter-img"  :src="`/result/${myMbti.bad}.png`" alt="캐릭터 이미지" />
          <div class="title">{{bad}}</div>
          <div class="type">{{myMbti.bad}}</div>
        </div>
      </div>
    </div>
    <Button class="invert" @confirm="moveToHome">테스트 다시하기</Button>
    <a
      class="icon"
      target="_blank"
      href="https://jiinlove.typeform.com/mainprofile#source=MBTI2"
    >
      <div class="button"> 무료 상담신청⭐️ </div>
    </a>
    <div class="share"> 친구들에게 공유하기 </div>
    <Share />
    <div class="iframe">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/Lt9YHwaIJFM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Button from "@/components/Button";
import Share from "@/components/Share";
export default {
  name: "Result",
  components: {
    Button,
    Share,
  },
  computed: {
    ...mapState({
      result: state => state.result,
    })
  },
  data() {
    return {
      mbti: null,
      myMbti: {
        title: null,
        text: null,
        tag: null,
        status: {
          charm: 5,
          romance: 5,
          sexual: 5,
          drive: 5,
          energy: 5
        },
        good: null,
        bad: null
      },
      good: null,
      bad: null
    };
  },
  created() {
    this.mbti = this.$route.params.id;
    this.result[this.mbti] === undefined
      ? this.$router.push({
          name: "Home"
        })
      : (this.myMbti = this.result[this.mbti]);
    this.good = this.result[this.myMbti.good].title;
    this.bad = this.result[this.myMbti.bad].title;
  },
  methods: {
    moveToHome() {
      this.$router.push({
        name: "Home"
      });
    }
  }
};
</script>
<style lang="scss">
h1 {
  &.result-title {
    margin: 0;
  }
  &.result-type {
    margin: 0;
    text-transform: uppercase;
    color: var(--theme-color);
    font-size: 3.2em;
    position: relative;
    top: -0.2em;
  }
}
h2 {
  margin-top: 2.5em;
}
.parter-img {
  height: 100px;
  object-fit: cover;
}
.status-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2em;
  align-items: center;
  .row {
    display: flex;
    gap: 0.5em;
    align-items: center;
    margin-bottom: 0.5em;
  }
  .item {
    width: 80px;
    text-align: right;
  }
  .status {
    width: 200px;
    height: 8px;
    background: #efefef;
    border-radius: 20px;
    & > .bar {
      position: absolute;
      opacity: 1;
      height: 8px;
      border-radius: 20px 0 0 20px;
      &.charm {
        background: #ff3982;
      }
      &.romance {
        background: #d80a20;
      }
      &.sexual {
        background: #ff1616;
      }
      &.drive {
        background: #fbe4c7;
      }
      &.energy {
        background: #2cea8a;
      }
    }
    & > .length {
      &-1 {
        width: 40px;
      }
      &-2 {
        width: 80px;
      }
      &-3 {
        width: 120px;
      }
      &-4 {
        width: 160px;
      }
      &-5 {
        width: 200px;
        border-radius: 20px;
      }
    }
  }
}
.p-card {
  border: 1px solid #e1e1e1;
  padding: 1.5em 0.5em 0.5em;
  .title {
    font-family: byulbitcha;
    font-size: 1.5em;
    margin-top: 0.5em;
  }
  .type {
    font-family: byulbitcha;
    font-size: 1.5em;
    text-transform: uppercase;
    color: var(--theme-color);
  }
}
img.result-img {
  display: block;
  width: 80%;
  margin: 2em auto 1em;
}
.partner {
  display: flex;
  width: 100%;
  gap: 1em;
  margin-bottom: 3em;
  padding-bottom: 2em;
  border-bottom: 1px solid #cecece94;
  .item {
    width: 100%;
  }
}
.feature-head {
  font-size: 18px;
  margin: 32px 0 -8px;
  text-align: left;
  width: 100%;
}
.feature {
  text-align: left;
}
.button {
    background-color: #ff817e;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    line-height: 0.8;
    text-transform: uppercase;
    padding: 20px 15px;
    margin-top: 3rem;
    width: 25rem;
}
.iframe {
  margin-top: 3rem;
}
.share {
    font-family: byulbitcha;
    font-size: 1.5em;
    margin-top: 3em;
  }
</style>